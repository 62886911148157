import { AdjustmentTypePolicy } from "constants/charge/AdjustmentTypePolicy";
import { ChargeDetailStatePolicy } from "constants/charge/ChargeDetailStatePolicy";
import { ChargeTypePolicy } from "constants/charge/ChargeTypePolicy";
import { CommonAccTypePolicy } from "constants/charge/CommonAccTypePolicy";
import { ContainerStatusPolicy } from "constants/charge/ContainerStatusPolicy";
import { VesselChargeSubChargeTypePolicy } from "constants/charge/VesselChargeSubChargeTypePolicy";
import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { EMPTY_INITIAL_MENU_ITEMS_STATE, InitialMenuItemsState } from "presentation/constant/ChargeDetailMaintenance/InitialMenuItemsState";

/**
 * Depends on user selected charge to initial the menu items
 * @param selectedCharges 
 */
export const initialBulkMenu = (selectedCharges:ChargeDetailEntity[] | null) => {
    // let chargeTypeList:{chargetype?:string|null, subChargeType?:string|null}[] = [];
    let initialMenuItems:InitialMenuItemsState = {};
    if (selectedCharges) {
        initialMenuItems = {
            ...initialMenuItems,
            accResumeTerminalEnable: true,
            holdEnable: true,
            releaseEnable: true,
            updateVslVoyEnable: true,
            oneOffRateEnable: true,
            seqEnable: true,
            billingTypeEnable: true,
            billToCompanyEnable: true,
            sizeEnable: true,
            operatingTmlEnable: true,
            specialIndEnable: true,
            soNumberEnable: true,
            dgContentEnable: true,
            rfTempEnable: true,
            manualAdjEnable: true,
            copyCommonAcEnable: true,
            uptAbsolutelyCommonAcEnable: true,
            reverseCommonAccEnable: true,
            assCommonAccEnable: true,
            redirectCommonAccEnable: true,
            dischargeIndEnable: true,
            seclegOperatingTmlEnable: true,
            seclegSpecialIndEnable: true,
            waiverNoEnable: true,
            // status2Enable: true,
            cntrDtlEnable: true,
            emptyIndEnable: true,
            ignoreCntrStatusValidEnable: true,
            permitNoEnable: true,        
            rfInfoEnable: true,     
            alterableBillToCompanyEnable: false,
            alterableChargeOnCompanyEnable: false,
            emptyChargeQtyEnable: true,
            pooEnable: true,                   
            statusEnable: true,
            manualTsLegChargeIndEnable: true,
            bundleMasterNoEnable: true,
            tierFspEnable: true,
            voltageEnable: true,
            offStdMeasurementModeEnable: true,
            marshallingTypeEnable: true,
            specialHandlingCodeEnable: true,
            vesselInfoEnable: true,
            tsVesselInfoEnable: true,
        }
        // selectedCharges.forEach(function(charge, index) {
        //     const chargeTypeHandled = chargeTypeList.find(chargeType => charge.chargeType === chargeType.chargetype &&
        //         charge.subChargeType === chargeType.subChargeType
        //     )
        //     if (!chargeTypeHandled) {
        //         chargeTypeList.push({chargetype:charge.chargeType, subChargeType:charge.subChargeType})

        //         initialMenuItems = {
        //             ...initialMenuItems,
        //             accResumeTerminalEnable: true,
        //             holdEnable: true,
        //             releaseEnable: true,
        //             updateVslVoyEnable: true,
        //         }
        //         if (ChargeTypePolicy.CHARGE_TYPE_ITM === charge.chargeType) {
        //             initialMenuItems = {
        //                 ...initialMenuItems,
        //                 oneOffRateEnable: true,
        //                 seqEnable: true,
        //                 billingTypeEnable: true,
        //                 billToCompanyEnable: true,
        //                 sizeEnable: true,
        //                 operatingTmlEnable: true,
        //                 specialIndEnable: true,
        //                 soNumberEnable: true,
        //                 dgContentEnable: true,
        //                 rfTempEnable: true,
        //                 manualAdjEnable: true,
        //                 copyCommonAcEnable: true,
        //                 uptAbsolutelyCommonAcEnable: true,
        //                 reverseCommonAccEnable: true,
        //                 assCommonAccEnable: true,
        //                 redirectCommonAccEnable: true,
        //             }
                    
        //         } else if (ChargeTypePolicy.CHARGE_TYPE_GS === charge.chargeType) {
        //             initialMenuItems = {
        //                 ...initialMenuItems,
        //                 oneOffRateEnable: true,
        //                 seqEnable: true,
        //                 billingTypeEnable: true,
        //                 billToCompanyEnable: true,
        //                 sizeEnable: true,
        //                 operatingTmlEnable: true,
        //                 specialIndEnable: true,
        //                 soNumberEnable: true,                        
        //                 dgContentEnable: true,
        //                 rfTempEnable: true,
        //                 manualAdjEnable: true,
        //                 copyCommonAcEnable: true,
        //                 uptAbsolutelyCommonAcEnable: true,
        //                 reverseCommonAccEnable: true,
        //                 assCommonAccEnable: true,
        //                 redirectCommonAccEnable: true,
        //             }                                      
        //         } else if (ChargeTypePolicy.CHARGE_TYPE_OBS === charge.chargeType) {
        //             initialMenuItems = {
        //                 ...initialMenuItems,
        //                 oneOffRateEnable: true,
        //                 seqEnable: true,
        //                 billingTypeEnable: true,
        //                 billToCompanyEnable: true,
        //                 sizeEnable: true,
        //                 operatingTmlEnable: true,
        //                 specialIndEnable: true,
        //                 dischargeIndEnable: true,
        //                 soNumberEnable: true,                        
        //                 dgContentEnable: true,
        //                 rfTempEnable: true,
        //                 manualAdjEnable: true,
        //                 copyCommonAcEnable: true,
        //                 uptAbsolutelyCommonAcEnable: true,
        //                 reverseCommonAccEnable: true,
        //                 assCommonAccEnable: true,
        //                 redirectCommonAccEnable: true,
        //             }
        //         } else if (ChargeTypePolicy.CHARGE_TYPE_TSS === charge.chargeType) {
        //             initialMenuItems = {
        //                 ...initialMenuItems,
        //                 oneOffRateEnable: true,
        //                 seqEnable: true,
        //                 billingTypeEnable: true,
        //                 billToCompanyEnable: true,
        //                 sizeEnable: true,
        //                 operatingTmlEnable: true,
        //                 specialIndEnable: true,
        //                 seclegOperatingTmlEnable: true,
        //                 seclegSpecialIndEnable: true,
        //                 seclegDischargeIndEnable: true,
        //                 soNumberEnable: true,
        //                 waiverNoEnable: true,
        //                 dgContentEnable: true,
        //                 rfTempEnable: true,
        //                 manualAdjEnable: true,
        //                 copyCommonAcEnable: true,
        //                 uptAbsolutelyCommonAcEnable: true,
        //                 reverseCommonAccEnable: true,
        //                 assCommonAccEnable: true,
        //                 redirectCommonAccEnable: true,
        //             }                                                       
        //         } else if (ChargeTypePolicy.CHARGE_TYPE_MAR === charge.chargeType) {
        //             initialMenuItems = {
        //                 ...initialMenuItems,
        //                 oneOffRateEnable: true,
        //                 seqEnable: true,
        //                 billingTypeEnable: true,
        //                 billToCompanyEnable: true,
        //                 sizeEnable: true,
        //                 operatingTmlEnable: true,
        //                 specialIndEnable: true,                   
        //                 dgContentEnable: true,
        //                 rfTempEnable: true,
        //                 soNumberEnable: true,
        //                 status2Enable: true,
        //                 copyCommonAcEnable: true,
        //                 uptAbsolutelyCommonAcEnable: true,
        //                 reverseCommonAccEnable: true,
        //                 assCommonAccEnable: true,
        //                 redirectCommonAccEnable: true,
        //             }
        //         } else if (ChargeTypePolicy.CHARGE_TYPE_YH === charge.chargeType) {
        //             initialMenuItems = {
        //                 ...initialMenuItems,
        //                 oneOffRateEnable: true,
        //                 seqEnable: true,
        //                 cntrDtlEnable: true,
        //                 billingTypeEnable: true,
        //                 billToCompanyEnable: true,
        //                 sizeEnable: true,
        //                 operatingTmlEnable: true,
        //                 specialIndEnable: true,                   
        //                 dgContentEnable: true,
        //                 rfTempEnable: true,
        //                 soNumberEnable: true,
        //                 status2Enable: true,
        //                 emptyIndEnable: true,
        //                 ignoreCntrStatusValidEnable: true,
        //                 copyCommonAcEnable: true,
        //                 uptAbsolutelyCommonAcEnable: true,
        //                 reverseCommonAccEnable: true,
        //                 assCommonAccEnable: true,
        //                 redirectCommonAccEnable: true,
        //             }
        //         } else if (ChargeTypePolicy.CHARGE_TYPE_GEN === charge.chargeType) {
        //             initialMenuItems = {
        //                 ...initialMenuItems,
        //                 oneOffRateEnable: true,
        //                 seqEnable: true,
        //                 billingTypeEnable: true,
        //                 billToCompanyEnable: true,
        //                 sizeEnable: true,
        //                 operatingTmlEnable: true,
        //                 specialIndEnable: true,    
        //                 dischargeIndEnable: true,               
        //                 dgContentEnable: true,
        //                 permitNoEnable: true,
        //                 rfTempEnable: true,
        //                 status2Enable: true,
        //             }
        //         } else if (ChargeTypePolicy.CHARGE_TYPE_RFH === charge.chargeType) {
        //             initialMenuItems = {
        //                 ...initialMenuItems,
        //                 oneOffRateEnable: true,
        //                 seqEnable: true,
        //                 billingTypeEnable: true,
        //                 billToCompanyEnable: true,
        //                 sizeEnable: true,
        //                 operatingTmlEnable: true,
        //                 specialIndEnable: true, 
        //                 updateVslVoyEnable: true,              
        //                 rfInfoEnable: true,                        
        //             }                    
        //         } else if (ChargeTypePolicy.CHARGE_TYPE_EMP === charge.chargeType) {
        //             initialMenuItems = {
        //                 ...initialMenuItems,
        //                 seqEnable: true,
        //                 billingTypeEnable: true,
        //                 billToCompanyEnable: true,
        //                 alterableBillToCompanyEnable: false,
        //                 alterableChargeOnCompanyEnable: false,
        //                 sizeEnable: true,
        //                 operatingTmlEnable: true,
        //                 specialIndEnable: true,
        //                 updateVslVoyEnable: true,
        //                 emptyChargeQtyEnable: true
        //             }
        //         } else if (ChargeTypePolicy.CHARGE_TYPE_CY === charge.chargeType) {
        //             if ("VesselCharge" === charge.subChargeType) {
        //                 initialMenuItems = {
        //                     ...initialMenuItems,
        //                     oneOffRateEnable: true,
        //                     seqEnable: true,
        //                     billingTypeEnable: true,
        //                     billToCompanyEnable: true,
        //                     assCommonAccEnable: true,
        //                 }
        //             } else {
        //                 initialMenuItems = {
        //                     ...initialMenuItems,
        //                     oneOffRateEnable: true,
        //                     seqEnable: true,
        //                     cntrDtlEnable: true,
        //                     billingTypeEnable: true,
        //                     billToCompanyEnable: true,
        //                     sizeEnable: true,
        //                     operatingTmlEnable: true,
        //                     pooEnable: true,                   
        //                     statusEnable: true,
        //                     emptyIndEnable: true,
        //                     ignoreCntrStatusValidEnable: true,
        //                     copyCommonAcEnable: true,
        //                     uptAbsolutelyCommonAcEnable: true,
        //                     reverseCommonAccEnable: true,
        //                     assCommonAccEnable: true,
        //                     redirectCommonAccEnable: true,
        //                     manualTsLegChargeIndEnable: true,
        //                 }
        //             }
        //         } else if (ChargeTypePolicy.CHARGE_TYPE_VGM === charge.chargeType) {
        //             initialMenuItems = {
        //                 ...initialMenuItems,
        //                 oneOffRateEnable: true,
        //                 seqEnable: true,
        //                 updateVslVoyEnable: true,                        
        //                 billingTypeEnable: true,
        //                 billToCompanyEnable: true,
        //                 copyCommonAcEnable: true,
        //                 uptAbsolutelyCommonAcEnable: true,
        //                 reverseCommonAccEnable: true,
        //                 assCommonAccEnable: true,
        //                 redirectCommonAccEnable: true,
        //             }
        //         }
        //     }
        // })
        return initialMenuItems;
    } else {
        return initialMenuItems;
    }
}

/**
 * mark the bulk menu item to disable depend on user's selected charges
 * @param selectedCharges 
 * @returns 
 */
export const setChargeBulkMenuMark = (selectedCharges:ChargeDetailEntity[] | null) => {
    if (!selectedCharges) return null;

    let isCanceled = false;
    let isAdjustment = false;
    let isCancelAndRebill = false;
    let isNormal = false;
    let hasE = false;
    let hasF = false;
    let hasR = false;
    let hasT = false;
    //fix log101642 by John
    let hasTandRef = false;
    let hasMT = false;
    let hasH = false;
    let hasI = false;
    let hasC = false;
    let hasMH = false;
    let hasSH = false;
    let hasDI=false;
    let hasSA=false;
    // let isAllRESHAndCYIS = true;
    //fix log101643 by John
    let isAllCYAndYH = true;
    let isAllNotPRateAndCommonAcct = true;  
    let isExitOperatingTml = true;
    //fix CR#103591 by carl
    let isAllCommonAcctCharge = true;
    let isMscChg = true;    
    let initialMenuItems:InitialMenuItemsState|undefined = initialBulkMenu(selectedCharges);
    let bundleChgKeys:string[] = [];

    if (!initialMenuItems) {
        initialMenuItems = {};
    }

    if (!selectedCharges || (selectedCharges && selectedCharges.length <= 0)) {
        return EMPTY_INITIAL_MENU_ITEMS_STATE;
    }
    
    selectedCharges.forEach(function(charge, index) {
        if(!charge.operatingTml){
            isExitOperatingTml = false;
        }
        if (AdjustmentTypePolicy.ADJ_TYPE_CANCEL === charge.adjType) {
            isCanceled = true;
        } else if (AdjustmentTypePolicy.ADJ_TYPE_ADJUSTMENT === charge.adjType) {
            isAdjustment = true;
        } else if (AdjustmentTypePolicy.ADJ_TYPE_CANCEL_REBILL === charge.adjType) {
            isCancelAndRebill = true;
        } else if (AdjustmentTypePolicy.ADJ_TYPE_NORMAL === charge.adjType) {
            isNormal = true;
        }
        if (ChargeDetailStatePolicy.STATE_C === charge.dtlState) {
            hasC = true;
        } else if (ChargeDetailStatePolicy.STATE_I === charge.dtlState) {
            hasI = true;
        } else if (ChargeDetailStatePolicy.STATE_E === charge.dtlState) {
            hasE = true;
        } else if (ChargeDetailStatePolicy.STATE_R === charge.dtlState) {
            hasR = true;
        } else if (ChargeDetailStatePolicy.STATE_F === charge.dtlState) {
            hasF = true;
        } else if (ChargeDetailStatePolicy.STATE_T === charge.dtlState) {
            //fix log101642 by John
            if ("BILL_IN_OTHER_OPERATING_COMPANY" === charge.accountTmlReasonCode) {
                hasTandRef = true;
            }
            hasT = true;
        } else if (ChargeDetailStatePolicy.STATE_MT === charge.dtlState) {
            hasMT = true;
        } else if (ChargeDetailStatePolicy.STATE_SH === charge.dtlState) {
            hasSH = true;
        } else if (ChargeDetailStatePolicy.STATE_MH === charge.dtlState) {
            hasMH = true;
        } else if (ChargeDetailStatePolicy.STATE_H === charge.dtlState) {
            hasH = true;
        } else if (ChargeDetailStatePolicy.STATE_DI === charge.dtlState) {
            hasDI = true;
        } else if (ChargeDetailStatePolicy.STATE_SA === charge.dtlState) {
            hasSA = true;
        }
        if (!(VesselChargeSubChargeTypePolicy.CODE_RESH === charge.subChargeType) &&
                !(VesselChargeSubChargeTypePolicy.CODE_RESH_IS === charge.subChargeType)&&
                !(VesselChargeSubChargeTypePolicy.CODE_CY_IS === charge.subChargeType)) {
            // isAllRESHAndCYIS = false;
        }
        //fix log101643 by John
        if (!(VesselChargeSubChargeTypePolicy.CODE_CY === charge.subChargeType) &&
                !(VesselChargeSubChargeTypePolicy.CODE_YH === charge.subChargeType)) {
            isAllCYAndYH = false;
        }
        if (charge.chargeShareType) {
            isAllNotPRateAndCommonAcct = false;
        }
        
        if (!("MSC" === charge.billToCompany
                && "2700" === charge.operatingCompany)) {
            isMscChg = false;
        }

        let bundleChgKey = (charge.cntrNo??"") + "_" + (charge.cntrVisitId??"") + "_" + (charge.operationType??"");
        if(!bundleChgKeys.includes(bundleChgKey)){
            bundleChgKeys.push(bundleChgKey);
        }
    });

    if(bundleChgKeys && bundleChgKeys.length > 4){
        initialMenuItems = {
            ...initialMenuItems,            
            bundleMasterNoEnable: false,
        }
    }

    if (isCanceled || hasI || hasC || hasSH || hasMH || hasDI || hasSA
        || ((hasT || hasMT) && hasH)) {
        initialMenuItems = {
            ...EMPTY_INITIAL_MENU_ITEMS_STATE
        }
        if (isCanceled && !hasI && !hasC && !hasSH && !hasMH && !hasDI && !hasSA
                && !((hasT || hasMT) && hasH)) {
            initialMenuItems = {
                ...initialMenuItems,
                accResumeTerminalEnable: true,
                holdEnable: true,
            }
        }
    } else {
        if (hasT || hasMT || hasH) {
            //fix log101642 by John
            initialMenuItems = {
                ...EMPTY_INITIAL_MENU_ITEMS_STATE
            };
            if ((hasT && !hasTandRef) || hasMT) {
                initialMenuItems = {
                    ...initialMenuItems,
                    accResumeTerminalEnable: true,
                }
            }
            if (hasH) {
                initialMenuItems = {
                    ...initialMenuItems,
                    holdEnable: true,
                }
            } 
        } else if (hasE || hasF || hasR) {
            if (isCancelAndRebill || isAdjustment || isCanceled) {
                initialMenuItems = {
                    ...initialMenuItems,
                    assParticipationRateEnable: false,
                }
            }
            if (isAdjustment || isCanceled) {
                initialMenuItems = {
                    ...EMPTY_INITIAL_MENU_ITEMS_STATE
                };
                if (isAdjustment) {
                    initialMenuItems = {
                        ...initialMenuItems,
                        oneOffRateEnable: true,
                        accResumeTerminalEnable: true,
                        holdEnable: true,
                        seqEnable: true
                    }
                } else if (isCanceled) {
                    initialMenuItems = {
                        ...initialMenuItems,
                        accResumeTerminalEnable: true,
                        holdEnable: true,
                    }
                }
                
            } else {
                //fix CR#103591 by carl
//                    if (!isAllRESHAndCYIS) {
                if (!isAllCommonAcctCharge) {
                    initialMenuItems = {
                        ...initialMenuItems,
                        assCommonAccEnable: false,
                    }
                }
                if (!(isAllCommonAcctCharge && isMscChg)) {
                    initialMenuItems = {
                        ...initialMenuItems,
                        copyCommonAcEnable: false,
                    }
                }
                //fix log101643 by John
                if (!isAllCYAndYH) {
                    initialMenuItems = {
                        ...initialMenuItems,
                        emptyIndEnable: false,
                    }
                }
                if (!isMiUptStatusEditable(selectedCharges)) {
                    initialMenuItems = {
                        ...initialMenuItems,
                        statusEnable: false,
                    }
                }
                if (!isAllNotPRateAndCommonAcct) {
                    initialMenuItems = {
                        ...initialMenuItems,
                        assParticipationRateEnable: false,
                        assCommonAccEnable: false,
                    }
                }                    
            }
            if (isNormal || isCancelAndRebill) {
                initialMenuItems = setMenuMaskForECT(selectedCharges, initialMenuItems);
            }
        }
        selectedCharges.forEach(function(entity) {
            // Enable the Manual TS Leg Charge Indicator Assignment in Bulk Edit Menu only when CY-CY 
            if (ChargeTypePolicy.CHARGE_TYPE_CY === entity.chargeType
                    && (VesselChargeSubChargeTypePolicy.CODE_CY !== entity.subChargeType)) {
                initialMenuItems = {
                    ...initialMenuItems,
                    manualTsLegChargeIndEnable: false,
                }
            }
            if (AdjustmentTypePolicy.ADJ_TYPE_NORMAL !== entity.adjType ||
                    (!entity.handlingTerminal &&
                     !entity.voy &&
                     !entity.vsl)) {
                initialMenuItems = {
                    ...initialMenuItems,
                    billingTypeEnable: false,
                }
            }
            if(AdjustmentTypePolicy.ADJ_TYPE_NORMAL !== entity.adjType) {
                initialMenuItems = {
                    ...initialMenuItems,
                    updateVslVoyEnable: false,
                }
            }
        });
    }
    initialMenuItems = {
        ...initialMenuItems,
        releaseEnable: false,
    }
    if (!hasE && !hasF && !hasR && !hasT && !hasMT && !hasH && !hasI && !hasC && hasMH && !hasSH && !hasDI && !hasSA) {
        initialMenuItems = {
            ...initialMenuItems,
            releaseEnable: true,
        }
    }
    if(!isExitOperatingTml){
        initialMenuItems = {
            ...initialMenuItems,
            operatingTmlEnable: false,
            seclegOperatingTmlEnable: false,
        }
    }

    return initialMenuItems;
}

/**
 * check if show update container status menu or not
 * @param selectedCharges 
 * @returns 
 */
export const isMiUptStatusEditable = (selectedCharges:ChargeDetailEntity[] | null) => {
    let comboboxItems:string[] = [];
    let statusList:string[] = getSelectedRowsStatus(selectedCharges);
    // let isOF = false;
    // let isOM = false;
    let isRE = false;
    let isRM = false;
    let isSH = false;
    let isSM = false;
    // let isXF = false;
    // let isXI = false;
    // let isXM = false;
    // let isEM = false;
    // let isIF = false;
    // let isIM = false;
    // let isTF = false;
    // let isTM = false;
    // if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_OF)) {
    //     isOF = true;
    // }
    // if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_OM)) {
    //     isOM = true;
    // }
    if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_RE)) {
        isRE = true;
    }
    if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_RM)) {
        isRM = true;
    }
    if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_SH)) {
        isSH = true;
    }
    if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_SM)) {
        isSM = true;
    }
    // if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_XF)) {
    //     isXF = true;
    // }
    // if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_XI)) {
    //     isXI = true;
    // }
    // if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_XM)) {
    //     isXM = true;
    // }
    // if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_EM)) {
    //     isEM = true;
    // }
    // if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_IF)) {
    //     isIF = true;
    // }
    // if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_IM)) {
    //     isIM = true;
    // }
    // if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_TF)) {
    //     isTF = true;
    // }
    // if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_TM)) {
    //     isTM = true;
    // }
    
    if (isRE || isRM || isSH || isSM) {
        comboboxItems.push(...ContainerStatusPolicy().getContainerStatusForI()); 
        comboboxItems.push(...ContainerStatusPolicy().getContainerStatusForX());
        comboboxItems.push(...ContainerStatusPolicy().getContainerStatusForE());
        comboboxItems.push(...ContainerStatusPolicy().getContainerStatusForO());  
        comboboxItems.push(...ContainerStatusPolicy().getContainerStatusForR());
        comboboxItems.push(...ContainerStatusPolicy().getContainerStatusForS());
    } else if (!(isRE || isRM || isSH || isSM)) {
        comboboxItems.push(...ContainerStatusPolicy().getContainerStatusForI()); 
        comboboxItems.push(...ContainerStatusPolicy().getContainerStatusForX());
        comboboxItems.push(...ContainerStatusPolicy().getContainerStatusForE());
        comboboxItems.push(...ContainerStatusPolicy().getContainerStatusForO());
        comboboxItems.push(...ContainerStatusPolicy().getContainerStatusForR());
        comboboxItems.push(...ContainerStatusPolicy().getContainerStatusForS());
    }
    
    if (comboboxItems == null || comboboxItems.length <= 0) {
        return null;
    } else {
        return comboboxItems;
    }
}

/**
 * collect all cntr status from selected charges
 * @param selectedCharges 
 * @returns 
 */
export const getSelectedRowsStatus = (selectedCharges:ChargeDetailEntity[] | null) => {
    let status:string[] = [];
    if (selectedCharges) {
        selectedCharges.forEach(function(charge) {
            if (charge.status && !status.includes(charge.status)) {
                status.push(charge.status);
            }
        });
    }

    return status;
}

	
export const setMenuMaskForECT = (selectedCharges:ChargeDetailEntity[] | null, initialMenuItems:InitialMenuItemsState) => {
    //fix CR#103591 by Vic
    let canReverseCommonAc = false;
    let isCommonAc = false;
    //fix CR#104541 by Vic
    let canReDirectCmAc = false;
    let canUptAbsltlyCmAc = false;
    
    initialMenuItems = {
        ...initialMenuItems,
        cntrDtlEnable: true,
    }
    //Fix 102518 by midas
    if (!selectedCharges) return initialMenuItems;

    selectedCharges.forEach(function(entity) {
        //Fix log102942 by vic
        if (!(VesselChargeSubChargeTypePolicy.CODE_CY === entity.subChargeType) &&
                !(VesselChargeSubChargeTypePolicy.CODE_YH === entity.subChargeType) &&
                !(VesselChargeSubChargeTypePolicy.CODE_YH === entity.subChargeType)) {
            initialMenuItems = {
                ...initialMenuItems,
                ignoreCntrStatusValidEnable: false,
            }
        }
        //fix CR#103591 by Vic
        if (entity.commonAcType) {
            isCommonAc = true;
            
            if (AdjustmentTypePolicy.ADJ_TYPE_NORMAL === entity.adjType
                    && !(ChargeDetailStatePolicy.STATE_C === entity.dtlState
                            || ChargeDetailStatePolicy.STATE_DI === entity.dtlState
                            || ChargeDetailStatePolicy.STATE_I === entity.dtlState)) {
                //fix CR#104541 by Vic
                if (!entity.reDirectFromTml) {
                    canReverseCommonAc = true;
                    
                    if(CommonAccTypePolicy.ITEM_QTY === entity.commonAcType) {
                        canUptAbsltlyCmAc = true;
                        
                        if("MSC" === entity.billToCompany) {
                            canReDirectCmAc = true;
                        }
                    }
                }
            }
        }
    });
    //fix CR#103591 by Vic
    if (isCommonAc) {
        initialMenuItems = {
            ...EMPTY_INITIAL_MENU_ITEMS_STATE,
            accResumeTerminalEnable: true,
            holdEnable: true,
            seqEnable: true,
            oneOffRateEnable: true
        };
        
        if (canReverseCommonAc) {
            initialMenuItems = {
                ...initialMenuItems,
                reverseCommonAccEnable: true
            };
        }
        
        //fix CR#104541 by vic
        if (canUptAbsltlyCmAc) {
            initialMenuItems = {
                ...initialMenuItems,
                uptAbsolutelyCommonAcEnable: true
            };
        }
        
        if (canReDirectCmAc) {
            initialMenuItems = {
                ...initialMenuItems,
                redirectCommonAccEnable: true
            };
        }
        
    } else {
        initialMenuItems = {
            ...initialMenuItems,
            reverseCommonAccEnable: false,
            uptAbsolutelyCommonAcEnable: false,
            redirectCommonAccEnable: false
        };
    }
    return initialMenuItems;
}
